






























import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../../core/Confirm.vue';

const lessons = namespace('lessons');
const classes = namespace('classes');

@Component
export default class MoveLessonAction extends Vue {
  targetDate = '';
  targetClassId = 0;
  localValue = false;
  localResolve: any;

  $refs!: {
    confirm: Confirm,
    form: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  @lessons.State
  date!: string;

  @lessons.State
  dayOfWeek!: string;

  @lessons.State
  className!: string;

  @lessons.State
  classId!: number;

  @classes.Getter('getClassItems')
  classItems!: Array<any>

  @classes.Getter
  getClassName!: (param?: string | number) => string;

  @lessons.Action('move')
  doMove!: (params?: any) => Promise<any>;

  public move() {
    this.localValue = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  get classDate() {
    return this.dayOfWeek + ' ' + this.date;
  }

  get targetClassName() {
    return this.getClassName(+this.targetClassId) || '';
  }

  get targetClassRules() {
    return [(v: string) => !!v || 'Required.'];
  }

  doInit() {
    if (this.localValue) {
      this.targetDate = '';
      this.targetClassId = this.classId;
    }
  }

  @Watch('localValue')
  onLocalValueChange() {
    CommonUtils.hideLoading();
    if (this.localValue) {
      this.doInit();
      try {
        this.$refs.form.resetValidation();
      } catch (e) {}
    }
  }

  mounted() {
    this.doInit();
  }

  async doApply(copyIt: string) {
    const that = this;
    const request = {
      copyIt,
      overwriteIt: 'N',
      classId: this.targetClassId,
      date: this.targetDate,
      extraLesson: 0
    }
    if (this.$refs.form.validate()) {
      CommonUtils.showLoading();
      this.doMove(request).then(async (resp) => {
        this.localValue = false;
        if (resp.data.returnMsg === 'lessonExists') {
          CommonUtils.hideLoading();
          return this.$refs.confirm.confirm({
            title: CommonUtils.isTrue(copyIt) ? this.$t('copyLessonLabel') : this.$t('moveLessonLabel'),
            text: this.$t('moveLessonMsg', { className: this.targetClassName, date: this.targetDate }),
            option1ButtonAlternativeText: this.$t('shiftLessonLabel'),
            option2ButtonAlternativeText: this.$t('overwriteLessonLabel')
          }).then(async (result) => {
            if (result === 1) {
              CommonUtils.showLoading();
              request.overwriteIt = 'S';
              return that.doMove(request).then(() => {
                return Promise.resolve();
              });
            } else if (result === 2) {
              CommonUtils.showLoading();
              request.overwriteIt = 'O';
              return that.doMove(request).then(() => {
                return Promise.resolve();
              });
            } else {
              return Promise.resolve();
            }
          });
        } else {
          return Promise.resolve();
        }
      }).then((resp) => {
        this.localResolve(resp);
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    } else {
      return Promise.resolve();
    }
  }
}

