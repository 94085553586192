
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../../core/Confirm.vue';

const lessons = namespace('lessons');
const classes = namespace('classes');
const settings = namespace('settings');

@Component
export default class LinkLessonAction extends Vue {
  targetDate = '';
  targetClassId = 0;
  localValue = false;
  localResolve: any;

  $refs!: {
    confirm: Confirm,
    observer: any,
    form: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  @lessons.State
  date!: string;

  @lessons.State
  dayOfWeek!: string;

  @lessons.State
  className!: string;

  @lessons.State
  classId!: number;

  @classes.Getter('getClassItems')
  classItems!: Array<any>

  @classes.Getter
  getClassName!: (param?: string | number) => string;

  @lessons.Action('link')
  linkLesson!: (params?: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  async doLink(params?: any) {
    return this.linkLesson(params).then(async resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('lessonsLinked') as string);
      }
      return Promise.resolve(resp);
    });
  }

  public link() {
    this.localValue = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  get classDate() {
    return this.dayOfWeek + ' ' + this.date;
  }

  get targetClassName() {
    return this.getClassName(+this.targetClassId) || '';
  }

  get targetClassRules() {
    return [(v: string) => !!v || 'Required.'];
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get htmlEscape() {
    return CommonUtils.htmlEscape;
  }

  doInit() {
    if (this.localValue) {
      this.targetDate = '';
      this.targetClassId = this.classId;
    }
  }

  @Watch('localValue')
  onLocalValueChange() {
    CommonUtils.hideLoading();
    this.doInit();
    try {
      this.$refs.form.resetValidation();
    } catch (e) {}
  }

  mounted() {
    this.doInit();
  }

  async doApply() {
    const dateFieldsValid = await this.$refs.observer.validate();
    if (this.$refs.form.validate() && dateFieldsValid) {
      const that = this;
      const request: any = {
        targetExtraLesson: 0,
        targetStartDate: this.targetDate,
        targetSubjectId: this.targetClassId,
        verifyLinkAction: true
      }
      CommonUtils.showLoading();
      this.localValue = false;
      this.doLink(request).then(resp => {
        const data = resp.data;
        if (data.confirmLinkAction) {
          CommonUtils.hideLoading();
          return this.$refs.confirm.confirm({
            title: this.$t('linkLessonLabel'),
            text: this.$t('linkLessonMsg', { className: this.targetClassName, date: this.targetDate }),
            option1ButtonAlternativeText: this.$t('shiftLabel'),
            option2ButtonAlternativeText: this.$t('mergeLabel'),
            option3ButtonAlternativeText: this.$t('overwriteLabel')
          });
        } else {
          return Promise.resolve(0);
        }
      }).then(result => {
        if (result !== 0) {
          CommonUtils.showLoading();
          request.linkAction = result === 1 ? 0 : result === 2 ? 1 : 2;
          request.verifyLinkAction = result === 3 ? false : request.verifyLinkAction;
          return that.doLink(request);
        } else {
          return Promise.resolve({});
        }
      }).then((resp: any) => {
        const data = resp && resp.data ? resp.data : null;
        if (data && CommonUtils.isNotEmpty(data.deletedLessons)) {
          let deleteText = '';
          if (data.deletedLessons.length > 1) {
            deleteText += (data.deletedLessons.length + ' ' + this.$t('lessonsLabel')).toLowerCase()
          } else {
            deleteText += this.$t('aLessonLabel')
          }
          return this.$refs.confirm.confirm({
            title: this.$t('lessonLabel'),
            text: this.$t('confirmDeleteLesson3', { text: deleteText }),
            option1ButtonAlternativeText: this.$t('continueLabel')
          })
        } else {
          return Promise.resolve(0);
        }
      }).then(result => {
        if (result === 1) {
          CommonUtils.showLoading();
          request.verifyLinkAction = false;
          return that.doLink(request);
        } else {
          return Promise.resolve();
        }
      }).then(resp => {
        this.localResolve(resp);
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    } else {
      return Promise.resolve();
    }
  }
}

