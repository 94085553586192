var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 610,
        expandable: false,
        withActions: false,
        withApply: false,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_vm._v(_vm._s(_vm.$t("linkLessonLabel")))]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "div",
        { staticClass: "editor" },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-container",
                    [
                      _vm.localPage === "lesson-banks"
                        ? _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("actionLessonBankSubHeader", {
                                  action: _vm.$t("linkingSubHeaderLabel"),
                                  className: _vm.htmlEscape(_vm.className),
                                })
                              ),
                            },
                          })
                        : _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("actionLessonSubHeader", {
                                  action: _vm.$t("linkingSubHeaderLabel"),
                                  className: _vm.htmlEscape(_vm.className),
                                  date: _vm.classDate,
                                })
                              ),
                            },
                          }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-3", attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  "hide-details": "auto",
                                  rules: _vm.targetClassRules,
                                  outlined: "",
                                  items: _vm.classItems,
                                  label: _vm.$t("classLabel"),
                                },
                                model: {
                                  value: _vm.targetClassId,
                                  callback: function ($$v) {
                                    _vm.targetClassId = $$v
                                  },
                                  expression: "targetClassId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("pb-date-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  required: "",
                                  prependInnerIcon: false,
                                  label: _vm.$t("lessonDateLabel"),
                                },
                                model: {
                                  value: _vm.targetDate,
                                  callback: function ($$v) {
                                    _vm.targetDate = $$v
                                  },
                                  expression: "targetDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-5 mt-5" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { "offset-sm": "4", cols: "12", sm: "4" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "error" },
                                  on: { click: _vm.doApply },
                                },
                                [_vm._v(_vm._s(_vm.$t("linkLessonLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }