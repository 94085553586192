var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute" || _vm.zoom != 1,
        "position-x": _vm.x,
        "position-y": _vm.y,
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function ({ on: onMenu }) {
                  return [
                    _c(
                      "pb-btn",
                      _vm._g(
                        {
                          ref: "actionsButton",
                          attrs: {
                            icon: "",
                            right: "",
                            label: _vm.$t("actionsLabel"),
                          },
                          on: {
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                            ],
                            keyup: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "left",
                                    37,
                                    $event.key,
                                    ["Left", "ArrowLeft"]
                                  )
                                )
                                  return null
                                if ("button" in $event && $event.button !== 0)
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                )
                                  return null
                                if ("button" in $event && $event.button !== 2)
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "escape",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                )
                                  return null
                                if ("button" in $event && $event.button !== 2)
                                  return null
                                return _vm.focusExtraOptions.apply(
                                  null,
                                  arguments
                                )
                              },
                            ],
                          },
                        },
                        { ...onMenu }
                      ),
                      [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                      1
                    ),
                  ]
                },
              }
            : _vm.type == "custom"
            ? {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "div",
                      _vm._g(
                        _vm._b(
                          {
                            ref: "actionsButton",
                            attrs: {
                              tabindex: "0",
                              role: "button",
                              "aria-label": _vm.$t("actionsLabel"),
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.$emit("focus")
                              },
                              blur: function ($event) {
                                return _vm.$emit("blur")
                              },
                              keyup: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  _vm.showMenu = true
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "left",
                                      37,
                                      $event.key,
                                      ["Left", "ArrowLeft"]
                                    )
                                  )
                                    return null
                                  if ("button" in $event && $event.button !== 0)
                                    return null
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "right",
                                      39,
                                      $event.key,
                                      ["Right", "ArrowRight"]
                                    )
                                  )
                                    return null
                                  if ("button" in $event && $event.button !== 2)
                                    return null
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "escape",
                                      undefined,
                                      $event.key,
                                      undefined
                                    )
                                  )
                                    return null
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "right",
                                      39,
                                      $event.key,
                                      ["Right", "ArrowRight"]
                                    )
                                  )
                                    return null
                                  if ("button" in $event && $event.button !== 2)
                                    return null
                                  return _vm.focusExtraOptions.apply(
                                    null,
                                    arguments
                                  )
                                },
                              ],
                              keydown: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  )
                                    return null
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "up",
                                      38,
                                      $event.key,
                                      ["Up", "ArrowUp"]
                                    )
                                  )
                                    return null
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              ],
                            },
                          },
                          "div",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_vm._t("customButton")],
                      2
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _vm.userMode == "T"
        ? _c(
            "div",
            {
              staticClass: "editor-actions",
              class: {
                "mobile-editor-actions": _vm.$vuetify.breakpoint.smAndDown,
              },
            },
            [
              _c(
                "v-list",
                {
                  ref: "scrollableList",
                  staticClass: "overflow-y-auto",
                  staticStyle: { "max-height": "90vh" },
                  attrs: { dense: "" },
                },
                [
                  _vm.hasClassName
                    ? _c(
                        "div",
                        {
                          staticClass: "text-center font-weight-bold mb-1 mx-1",
                          style: {
                            color: _vm.$vuetify.theme.currentTheme.info,
                            "font-size": "12px",
                            "max-width": "280px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.actionHeader))]
                      )
                    : _vm._e(),
                  (!_vm.collaborateSubjectId ||
                    _vm.collaborateSubjectId <= 0) &&
                  _vm.isLessonStatusAllowed
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "lesson-statuses pb-2",
                          attrs: { tabindex: "0" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-0",
                                  attrs: { justify: "center" },
                                },
                                _vm._l(
                                  _vm.localAllLessonStatus,
                                  function (status, index) {
                                    return _c(
                                      "pb-btn",
                                      {
                                        key: status.lessonStatusId,
                                        ref: "status" + index,
                                        refInFor: true,
                                        attrs: {
                                          icon: "",
                                          label: status.statusText,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addRemoveStatus(status)
                                          },
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.addRemoveStatus(status)
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.focusActionButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              )
                                                return null
                                              return _vm.focusActionButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "right",
                                                  39,
                                                  $event.key,
                                                  ["Right", "ArrowRight"]
                                                )
                                              )
                                                return null
                                              if (
                                                "button" in $event &&
                                                $event.button !== 2
                                              )
                                                return null
                                              return _vm.focusStatus(index + 1)
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "left",
                                                  37,
                                                  $event.key,
                                                  ["Left", "ArrowLeft"]
                                                )
                                              )
                                                return null
                                              if (
                                                "button" in $event &&
                                                $event.button !== 0
                                              )
                                                return null
                                              return _vm.focusStatus(index - 1)
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("v-icon", {
                                          attrs: { color: status.color },
                                          domProps: {
                                            textContent: _vm._s(status.icon),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "pb-btn",
                                {
                                  ref: "manageStatusButton",
                                  staticClass: "pt-2",
                                  attrs: {
                                    label: _vm.$t("manageTagsLabel"),
                                    icon: "",
                                  },
                                  on: {
                                    click: _vm.manageStatus,
                                    keyup: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.manageStatus.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "left",
                                            37,
                                            $event.key,
                                            ["Left", "ArrowLeft"]
                                          )
                                        )
                                          return null
                                        if (
                                          "button" in $event &&
                                          $event.button !== 0
                                        )
                                          return null
                                        return _vm.focusStatus(
                                          _vm.localAllLessonStatus.length - 1
                                        )
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.focusActionButton.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        return _vm.focusActionButton.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    ],
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-cog")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("v-divider", { staticStyle: { margin: "10px 0px" } }),
                  _vm._l(_vm.filteredActions, function (item, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: i,
                        attrs: { link: "" },
                        on: {
                          click: function ($event) {
                            return _vm.doAction(item)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "mr-3" },
                          [
                            _c("v-icon", {
                              domProps: { textContent: _vm._s(item.icon) },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              staticStyle: { width: "130px" },
                              domProps: {
                                textContent: _vm._s(_vm.$t(item.label)),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-action",
                          [
                            item.id == "copy"
                              ? [
                                  _c("copy-items-picker", {
                                    ref: "extraOption" + i,
                                    refInFor: true,
                                    on: { blur: _vm.focusActionButton },
                                    model: {
                                      value: _vm.showCopyMenu,
                                      callback: function ($$v) {
                                        _vm.showCopyMenu = $$v
                                      },
                                      expression: "showCopyMenu",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            item.id == "forward"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "editor-sub-action" },
                                    [
                                      _c("plus-minus-field", {
                                        ref: "extraOption" + i,
                                        refInFor: true,
                                        on: { blur: _vm.focusActionButton },
                                        model: {
                                          value: _vm.forward,
                                          callback: function ($$v) {
                                            _vm.forward = $$v
                                          },
                                          expression: "forward",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.id == "backward"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "editor-sub-action" },
                                    [
                                      _c("plus-minus-field", {
                                        ref: "extraOption" + i,
                                        refInFor: true,
                                        on: { blur: _vm.focusActionButton },
                                        model: {
                                          value: _vm.backward,
                                          callback: function ($$v) {
                                            _vm.backward = $$v
                                          },
                                          expression: "backward",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.id == "extend"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "editor-sub-action" },
                                    [
                                      _c("plus-minus-field", {
                                        ref: "extraOption" + i,
                                        refInFor: true,
                                        on: { blur: _vm.focusActionButton },
                                        model: {
                                          value: _vm.extend,
                                          callback: function ($$v) {
                                            _vm.extend = $$v
                                          },
                                          expression: "extend",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.id == "extendStandards"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "editor-sub-action" },
                                    [
                                      _c("plus-minus-field", {
                                        ref: "extraOption" + i,
                                        refInFor: true,
                                        on: { blur: _vm.focusActionButton },
                                        model: {
                                          value: _vm.extendStandards,
                                          callback: function ($$v) {
                                            _vm.extendStandards = $$v
                                          },
                                          expression: "extendStandards",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.id == "print"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      ref: "extraOption" + i,
                                      refInFor: true,
                                      staticClass:
                                        "editor-sub-action copy-action",
                                      attrs: { tabindex: "0", role: "button" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.doPrintOptions.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        keydown: [
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.doPrintOptions.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "left",
                                                37,
                                                $event.key,
                                                ["Left", "ArrowLeft"]
                                              )
                                            )
                                              return null
                                            if (
                                              "button" in $event &&
                                              $event.button !== 0
                                            )
                                              return null
                                            return _vm.focusActionButton.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            )
                                              return null
                                            return _vm.focusActionButton.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.focusActionButton.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("div", [_vm._v("Options")]),
                                      _c("i", {
                                        staticClass: "fal fa-angle-right",
                                      }),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            item.id == "applyTemplate"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "editor-sub-action" },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            right: "",
                                            "close-on-content-click": true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            ref:
                                                              "extraOption" + i,
                                                            refInFor: true,
                                                            staticClass:
                                                              "editor-sub-action",
                                                            attrs: {
                                                              tabindex: "0",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "templatesLabel"
                                                                ),
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  $event.stopPropagation()
                                                                  _vm.showApplyMenu = true
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "left",
                                                                      37,
                                                                      $event.key,
                                                                      [
                                                                        "Left",
                                                                        "ArrowLeft",
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  if (
                                                                    "button" in
                                                                      $event &&
                                                                    $event.button !==
                                                                      0
                                                                  )
                                                                    return null
                                                                  return _vm.focusActionButton.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp",
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  $event.stopPropagation()
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown",
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  $event.stopPropagation()
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fal fa-angle-right",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.showApplyMenu,
                                            callback: function ($$v) {
                                              _vm.showApplyMenu = $$v
                                            },
                                            expression: "showApplyMenu",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              staticClass:
                                                "overflow-y-auto pb-0",
                                              staticStyle: {
                                                "max-width": "300px",
                                                "max-height": "90vh",
                                              },
                                              attrs: { dense: "" },
                                            },
                                            [
                                              _vm._l(
                                                _vm.favoriteTemplates,
                                                function (template, index) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        dense: "",
                                                        link: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.doApplyTemplate(
                                                            template
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "text-wrap",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  template.title
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _c("v-divider"),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "editor-actions" },
            [
              _c(
                "v-list",
                {
                  ref: "scrollableList",
                  staticClass: "overflow-y-auto",
                  staticStyle: { "max-height": "90vh" },
                  attrs: { dense: "" },
                },
                [
                  _vm.hasClassName
                    ? _c(
                        "div",
                        {
                          staticClass: "text-center font-weight-bold mb-1 mx-1",
                          style: {
                            color: _vm.$vuetify.theme.currentTheme.info,
                            "font-size": "12px",
                            "max-width": "280px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.actionHeader))]
                      )
                    : _vm._e(),
                  _vm.filteredAdminActions.length > 0
                    ? _c("v-divider", { staticStyle: { margin: "10px 0px" } })
                    : _vm._e(),
                  _vm._l(_vm.filteredAdminActions, function (item, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: i,
                        attrs: { link: "" },
                        on: {
                          click: function ($event) {
                            return _vm.doAction(item)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "mr-3" },
                          [
                            _c("v-icon", {
                              domProps: { textContent: _vm._s(item.icon) },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              staticStyle: { width: "130px" },
                              domProps: {
                                textContent: _vm._s(_vm.$t(item.label)),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-action",
                          [
                            item.id == "copy"
                              ? [
                                  _c("copy-items-picker", {
                                    model: {
                                      value: _vm.showCopyMenu,
                                      callback: function ($$v) {
                                        _vm.showCopyMenu = $$v
                                      },
                                      expression: "showCopyMenu",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
      _c("pb-confirm", { ref: "confirm" }),
      _c("move-lesson-action", { ref: "moveAction" }),
      _c("swap-lesson-action", { ref: "swapAction" }),
      _c("link-lesson-action", { ref: "linkAction" }),
      _c("classwork-lesson-action", {
        ref: "classworkAction",
        attrs: { input: _vm.input, fromLessonAction: true },
        on: { saveClasswork: _vm.doSaveClasswork },
      }),
      _c("print-options", { ref: "printOptionsAction" }),
      _c("lesson-manage-status", { ref: "manageStatus" }),
      _c(
        "pb-base-modal",
        {
          attrs: {
            input: _vm.input,
            withApply: false,
            expandable: false,
            maxWidth: 700,
            withActions: false,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("changeHistoryLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowChangeHistory,
            callback: function ($$v) {
              _vm.localShowChangeHistory = $$v
            },
            expression: "localShowChangeHistory",
          },
        },
        [
          _c("div", { staticClass: "pa-0 pt-0" }, [
            _vm.hasChangeHistory
              ? _c(
                  "div",
                  [
                    _c("v-simple-table", {
                      attrs: { "fixed-header": "", height: "500px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(_vm.$t("lastUpdateLabel"))),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("updatedFieldsLabel"))
                                      ),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(_vm.$t("updatedByLabel"))),
                                    ]),
                                    _c("th", { staticClass: "text-left" }),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.changeHistory, function (item) {
                                    return _c("tr", { key: item.id }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.timezoneDate(
                                              _vm.formatTimeStampToDisplay(
                                                item.revisionDate,
                                                true
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "200px",
                                              "word-wrap": "break-word",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getFields(item.fields))
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.emailAddress)),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _vm.hasOldLesson(item)
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    "aria-label":
                                                      _vm.$t("showDiffLabel"),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.showDiff(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "fal fa-table-columns"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.hasOldLesson(item)
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    "aria-label":
                                                      _vm.$t("restoreLabel"),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.doRestore(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "fal fa-rotate-left"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2792109849
                      ),
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "text-center pa-5",
                    staticStyle: { "min-height": "500px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("changeHistoryMsg1")) + " ")]
                ),
          ]),
        ]
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            input: _vm.input,
            withApply: false,
            expandable: false,
            maxWidth: 900,
            withActions: false,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("lessonUpdateLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowDiff,
            callback: function ($$v) {
              _vm.localShowDiff = $$v
            },
            expression: "localShowDiff",
          },
        },
        [
          _c("lesson-diff", {
            attrs: {
              originalLesson: _vm.oldData,
              updatedLesson: _vm.updatedData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }