var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { right: "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      ref: "menuActivator",
                      staticClass: "editor-sub-action copy-action",
                      attrs: { tabindex: "0" },
                      on: {
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown",
                              ])
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp",
                              ])
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "escape",
                                undefined,
                                $event.key,
                                undefined
                              )
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft",
                              ])
                            )
                              return null
                            if ("button" in $event && $event.button !== 0)
                              return null
                            return _vm.$emit("blur")
                          },
                        ],
                      },
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("div", [_vm._v(_vm._s(_vm.copyLabel))]),
                  _c("i", { staticClass: "fal fa-angle-right" }),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "v-list",
        { staticClass: "pb-0", attrs: { dense: "" } },
        [
          _vm._l(_vm.filteredCopyItems, function (item) {
            return _c(
              "v-list-item",
              {
                key: item.id,
                attrs: { dense: "", link: "" },
                on: {
                  click: function ($event) {
                    return _vm.onItemClick(item)
                  },
                },
              },
              [
                _c(
                  "v-list-item-content",
                  [_c("v-list-item-title", [_vm._v(_vm._s(item.label))])],
                  1
                ),
                _c(
                  "v-list-item-action",
                  { staticClass: "my-2" },
                  [
                    _c("v-checkbox", {
                      attrs: { dense: "", "input-value": item.selected },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              attrs: { dense: "", link: "" },
              on: {
                click: function ($event) {
                  _vm.localValue = false
                },
              },
            },
            [
              _c("v-list-item-title", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("doneLabel"))),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }