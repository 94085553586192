
import { UserSession } from '@/common/user-session';
import { lessonActions, lessonActionsAdmin } from '@/constants';
import LessonServices from '@/services/lesson-services';
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SnotifyToast } from 'vue-snotify';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import PlusMinusField from '../core/PlusMinusField.vue';
import CopyItemsPicker from '../pickers/CopyItemsPicker.vue';
import PrintOptions from '../prints/PrintOptions.vue';
import ClassworkLessonAction from './actions/ClassworkLessonAction.vue';
import LessonDiff from './actions/LessonDiff.vue';
import LinkLessonAction from './actions/LinkLessonAction.vue';
import MoveLessonAction from './actions/MoveLessonAction.vue';
import SwapLessonAction from './actions/SwapLessonAction.vue';
import LessonManageStatus from './LessonManageStatus.vue';
const settings = namespace('settings');
const lessonactions = namespace('lessonactions');
const schoolstatus = namespace('schoolstatus');
const lessons = namespace('lessons');
const classes = namespace('classes');
const prints = namespace('prints');
const students = namespace('students');
const templates = namespace('templates');
const plans = namespace('plans');

@Component({
  components: {
    PlusMinusField,
    CopyItemsPicker,
    MoveLessonAction,
    SwapLessonAction,
    LinkLessonAction,
    PrintOptions,
    LessonManageStatus,
    ClassworkLessonAction,
    LessonDiff
  }
})
export default class LessonActions extends Vue implements ActionsModal {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: {}, exclude: [] };
    }
  })
  input!: any;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

  showCopyMenu = false;
  showApplyMenu = false;
  showMenu = false;
  x = 0;
  y = 0;

  save = false;
  initialized = false;

  @lessons.State
  className!: string;

  @lessons.State
  dayOfWeek!: string;

  @lessons.State
  date!: string;

  @lessons.State
  classId!: number;

  @lessons.State('date')
  lessonDate!: string;

  @lessons.State
  extraLesson!: number;

  @lessons.State
  linkedLessonId!: number;

  @lessons.State
  lessonId!: number;

  @lessons.State
  collaborateSubjectId!: number;

  @settings.Getter('getStatuses')
  allLessonStatus!: Array<any>;

  @lessons.Getter
  headerTitle!: string;

  @settings.Getter('getSchoolStatuses')
  allSchoolStatus!: Array<any>;

  @settings.Getter('getUserMode')
  userMode!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @lessonactions.Getter('getCopyLabel')
  copyLabel!: any;

  @lessons.Getter('hasData')
  hasData!: any;

  @classes.Getter('getAllClassItemValues')
  classIds!: any;

  @lessons.Action
  init!: (params?: any) => Promise<any>;

  @lessons.Action('save')
  doSave!: (params?: any) => Promise<any>;

  @lessons.Action('bump')
  doBump!: (params?: any) => Promise<any>;

  @lessons.Action('extendStandards')
  doExtendStandards!: (params?: any) => Promise<any>;

  @lessons.Action('extend')
  doExtend!: (params?: any) => Promise<any>;

  @lessons.Action('delete')
  doDelete!: (params?: any) => Promise<any>;

  @lessons.Action('unlink')
  unlink!: (params?: any) => Promise<any>;

  @lessons.Action('removeClassDay')
  doRemoveClassDay!: (params?: any) => Promise<any>;

  @lessons.Action('copy')
  doCopy!: (params?: any) => Promise<any>;

  @lessons.Action('paste')
  doPaste!: (params?: any) => Promise<any>;

  @lessons.Action('undoBumpForward')
  doUndoBumpForward!: (params?: any) => Promise<any>;

  @lessons.Action('undoBumpBackward')
  doUndoBumpBackward!: (params?: any) => Promise<any>;

  @lessons.Action('undoDeleteLesson')
  doUndoDeleteLesson!: (params?: any) => Promise<any>;

  @lessons.Action
  restore!: (params: any) => Promise<any>;

  @lessons.Action
  loadChangeHistory!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @students.Action
  loadNotesStudents!: (params?: any) => Promise<any>;

  @prints.Action
  initDayPrint!: (value?: any) => Promise<any>;

  @prints.Action
  initBankPrint!: (value?: any) => Promise<any>;

  @prints.Action
  print!: (value?: any) => Promise<any>;

  @plans.State
  viewType!: string;

  @plans.Getter('getZoom')
  zoom!: number;

  @lessons.State
  changeHistory!: any[];

  @plans.Action
  reloadPlans!: () => Promise<any>;

  @templates.Action
  applyTemplate!: (value?: any) => Promise<any>;

  @templates.Action
  removeRestoreTemplate!: (value?: any) => Promise<any>;

  @lessons.Getter
  cloneState!: () => any;

  @templates.Getter
  getFavoriteTemplates!: any;

  localShowChangeHistory = false;
  localShowDiff = false;
  localChangeHistoryPayload: any = {};

  $refs!: {
    scrollableList: any,
    confirm: Confirm,
    moveAction: MoveLessonAction,
    swapAction: SwapLessonAction,
    linkAction: LinkLessonAction,
    classworkAction: ClassworkLessonAction,
    printOptionsAction: PrintOptions,
    manageStatus: LessonManageStatus,
  }

  get hasAssignments() {
    return LessonServices.hasAssignments(this.input.data.classId);
  }

  get hasAssessments() {
    return LessonServices.hasAssessments(this.input.data.classId);
  }

  get hasClassName() {
    return CommonUtils.hasText(this.localClassName);
  }

  get localClassName() {
    return this.className || '';
  }

  get favoriteTemplates() {
    if (this.getFavoriteTemplates.length > 0) {
      return this.getFavoriteTemplates;
    } else {
      return [{ title: this.$t('noFavoriteTemplates'), id: 0 }];
    }
  }

  get closeOnClick() {
    return !this.showCopyMenu;
  }

  get exclude() {
    return (this.input || {}).exclude || [];
  }

  get statuses() {
    return this.$store.state.lessons.statuses || [];
  }

  set statuses(value: any) {
    this.$store.commit('lessons/setStatuses', value);
    this.addUpdatedField('STATUSES');
  }

  get schoolStatuses() {
    return this.$store.state.lessons.schoolStatuses || [];
  }

  set schoolStatuses(value: any) {
    this.$store.commit('lessons/setSchoolStatuses', value);
    this.addUpdatedField('SSTATUSES');
  }

  get lessonLock() {
    return this.$store.state.lessons.lessonLock;
  }

  set lessonLock(value: any) {
    this.$store.commit('lessons/setLessonLock', value);
    this.addUpdatedField('LESSONLOCK');
  }

  get assignments() {
    return this.$store.state.lessons.assignments || [];
  }

  set assignments(value: any) {
    this.$store.commit('lessons/setAssignments', value);
    this.addUpdatedField('SCHOOLWORK');
  }

  get assessments() {
    return this.$store.state.lessons.assessments || [];
  }

  set assessments(value: any) {
    this.$store.commit('lessons/setAssessments', value);
    this.addUpdatedField('SCHOOLWORK');
  }

  get editLink() {
    return this.$store.state.lessons.editLink;
  }

  set editLink(value: any) {
    this.$store.commit('lessons/setEditLink', value);
  }

  get updatedFields(): Set<string> {
    return this.$store.state.lessons.updatedFields as Set<string>;
  }

  set updatedFields(value: Set<string>) {
    this.$store.commit('lessons/setUpdatedFields', value);
  }

  get forward() {
    return +this.$store.state.lessonactions.forward;
  }

  set forward(value: number) {
    this.$store.commit('lessonactions/setForward', value);
  }

  get backward() {
    return +this.$store.state.lessonactions.backward;
  }

  set backward(value: number) {
    this.$store.commit('lessonactions/setBackward', value);
  }

  get extend() {
    return +this.$store.state.lessonactions.extend;
  }

  set extend(value: number) {
    this.$store.commit('lessonactions/setExtend', value);
  }

  get extendStandards() {
    return +this.$store.state.lessonactions.extendStandards;
  }

  set extendStandards(value: number) {
    this.$store.commit('lessonactions/setExtendStandards', value);
  }

  get printOptions() {
    return this.$store.state.lessonactions.printOptions;
  }

  set printOptions(value: any) {
    this.$store.commit('lessonactions/setPrintOptions', value);
  }

  get isStatusEmpty() {
    return CommonUtils.isEmpty(this.allLessonStatus);
  }

  get selected() {
    return this.statuses.map((s: any) => s.lessonStatusId);
  }

  get selectedS() {
    return this.schoolStatuses.map((s: any) => s.schoolStatusId);
  }

  get saveLesson() {
    return this.save && this.input.loadData;
  }

  get actionHeader() {
    return this.headerTitle;
  }

  get adminActions() {
    return lessonActionsAdmin.filter(a =>
      (!this.exclude.includes(a.id) && this.localPage === 'lesson-banks') ||
      (a.id === 'comment' && this.lessonId > 0) ||
      (a.id === 'changeHistory' && this.lessonId > 0));
  }

  get filteredActions() {
    const that = this;
    return lessonActions.filter(a => {
      if (!this.exclude.includes(a.id)) {
        if (this.extraLesson === 999) {
          return ['lock'].includes(a.id);
        } else if (a.id === 'link') {
          return that.lessonId > 0;
        } else if (['unlink', 'editLink'].includes(a.id)) {
          return this.linkedLessonId > 0 || this.input.data.linkedLessonId > 0;
        } else if (a.id === 'applyTemplate') {
          return (!this.input.data.templateCustomDayCode || this.input.data.templateCustomDayCode !== 'BT');
        } else if (a.id === 'removeTemplate') {
          return (this.input.data.templateCustomDayCode && this.input.data.templateCustomDayCode !== 'BT');
        } else if (a.id === 'restoreTemplate') {
          return (this.input.data.templateCustomDayCode && this.input.data.templateCustomDayCode === 'BT');
        } else if (a.id === 'changeHistory') {
          return this.lessonId > 0;
        }
        return true;
      }
      return false;
    }).map(a => {
      if (a.id === 'lock') {
        a.icon = CommonUtils.isTrue(this.lessonLock) ? 'fal fa-unlock' : 'fal fa-lock';
        a.label = CommonUtils.isTrue(this.lessonLock) ? 'unlockLessonFromDateLabel' : 'lockLessonToDateLabel';
      }
      return a;
    });
  }

  get filteredAdminActions() {
    if (this.lessonId > 0) {
      return this.adminActions;
    } else {
      return [];
    }
  }

  get localAllLessonStatus() {
    return this.allLessonStatus.map(s => {
      s.icon = this.selected.includes(s.lessonStatusId) ? 'fas fa-check-circle' : 'fas fa-circle';
      return s;
    });
  }

  get localAllSchoolStatus() {
    return this.allSchoolStatus.filter((s: any) => !s.overrideDelete).map(s => {
      s.icon = this.selectedS.includes(s.schoolStatusId) ? 'fas fa-check-circle' : 'fas fa-circle';
      return s;
    });
  }

  get hasTeacherSchoolStatus() {
    return this.localAllSchoolStatus.find((s) => !s.adminOnly)
  }

  get formatTimeStampToDisplay() {
    return DateTimeUtils.formatTimestampToDisplay;
  }

  getFields(fields: string) {
    const arrFields = fields.split(',');
    return arrFields.join(', ');
  }

  get hasChangeHistory() {
    return CommonUtils.isNotEmpty(this.changeHistory);
  }

  manageStatus() {
    this.showMenu = false;
    if (this.localPage === 'plans') {
      this.$eventBus.$emit('openManageStatus');
    } else {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.$refs.manageStatus.manageStatus();
        });
      })
    }
  }

  addUpdatedField(field: string) {
    if (this.initialized) {
      this.updatedFields.add(field);
    }
  }

  focusExtraOptions() {
    try {
      const index = this.$refs.scrollableList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      }) - ((!this.collaborateSubjectId || this.collaborateSubjectId <= 0) ? 2 : 1);
      if (index >= 0) {
        (this.$refs as any)['extraOption' + (index)][0].focus();
      }
      if (index === -2) {
        (this.$refs as any).status0[0].$el.querySelector('.v-btn').focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusStatus(index: number) {
    try {
      if (index === -1) {
        this.focusActionButton();
      } else if (index >= this.localAllLessonStatus.length) {
        (this.$refs as any).manageStatusButton.$el.querySelector('.v-btn').focus();
      } else {
        (this.$refs as any)['status' + index][0].$el.querySelector('.v-btn').focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusActionButton() {
    try {
      if ((this.$refs as any).actionsButton.$el) {
        (this.$refs as any).actionsButton.$el.focus();
      } else {
        (this.$refs as any).actionsButton.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  public show(e: MouseEvent) {
    e.preventDefault()
    this.editLink = false;
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.showMenu = true;
      });
    })
  }

  get oldData() {
    if (this.localShowDiff) {
      const oldLesson = this.localChangeHistoryPayload.oldLesson;
      return LessonServices.createRequest(oldLesson);
    }
    return {};
  }

  get updatedData() {
    if (this.localShowDiff) {
      const updated = ld.cloneDeep(this.localChangeHistoryPayload);
      delete updated.oldLesson;
      return updated;
    }
    return {};
  }

  get isLessonLists() {
    return ['search', 'lesson-banks', 'reporting'].includes(this.localPage) || (this.localPage === 'plans' && this.viewType === 'L');
  }

  get isLessonStatusAllowed () {
    // based from displaying of type on LessonBanks.vue
    if ((this.input.data.districtId && this.input.data.districtId !== 0) || (this.input.data.schoolId && this.input.data.schoolId !== 0)) {
      return false;
    } else if (this.input.data.teacherId !== 0) {
      return true;
    }
    return false;
  }

  showDiff(item: any) {
    this.localShowDiff = true;
    if (CommonUtils.hasText(item.payload)) {
      this.localChangeHistoryPayload = JSON.parse(item.payload);
      const oldLesson = this.localChangeHistoryPayload.oldLesson;
      if (CommonUtils.hasText(oldLesson)) {
        this.localChangeHistoryPayload.oldLesson = JSON.parse(oldLesson);
      }
    } else {
      this.localChangeHistoryPayload = {};
    }
  }

  doRestore(item: any) {
    CommonUtils.showLoading();
    this.restore(item).then(() => {
      return this.loadChangeHistory();
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$eventBus.$emit('resetLessonBankData');
    });
  }

  hasOldLesson(item: any) {
    return item.payload && JSON.parse(item.payload).oldLesson;
  }

  @Watch('showMenu')
  resetScroll() {
    if (this.$refs.scrollableList) {
      this.$refs.scrollableList.$el.scrollTop = 0
    }
  }

  async doAction(action: any, index: any) {
    const that = this;
    CommonUtils.showLoading();
    return Promise.resolve()
      .then(async () => {
        if (action.id === 'edit') {
          return that.editLesson(false);
        } else if (action.id === 'editLink') {
          return that.doEditLink();
        } else if (action.id === 'lock') {
          return that.toggleLessonLock();
        } else if (action.id === 'copy') {
          return that.doCopy();
        } else if (action.id === 'forward') {
          return that.doBumpForward();
        } else if (action.id === 'backward') {
          return that.doBumpBackward();
        } else if (action.id === 'extend') {
          return that.doExtend({ numDays: this.extend }).then(resp => {
            const clone = this.cloneState();
            const data = resp.data;
            clone.numDays = this.extend;

            if (this.showSnackbarNotifications) {
              this.showNotification(this.headerTitle, this.$t('statusMsg2') as string, () => {
                CommonUtils.showLoading();
                this.doUndoBumpForward({ ...clone, bumpedLessonId: data.bumpedLessonId, action: 'UNDOEXTENDLESSON' }).then(() => {
                  this.$snotify.success(this.$t('statusMsg27') as string);
                  return Promise.resolve();
                }).then(CommonUtils.hideLoading);
              });
            }

            return Promise.resolve();
          });
        } else if (action.id === 'extendStandards') {
          return that.doExtendStandards({ numDays: this.extendStandards });
        } else if (action.id === 'delete') {
          return that.doDeleteLesson();
        } else if (action.id === 'noClass') {
          return that.doSetNoClassDay();
        } else if (action.id === 'unlink') {
          return that.doUnlinkLesson();
        } else if (action.id === 'paste') {
          return that.doPasteLesson();
        } else if (action.id === 'move') {
          return that.doMoveLesson();
        } else if (action.id === 'link') {
          return that.doLinkLesson();
        } else if (action.id === 'print') {
          return this.doPrintLesson();
        } else if (action.id === 'applyTemplate') {
          this.showApplyMenu = true;
        } else if (action.id === 'removeTemplate') {
          return this.doRemoveTemplate();
        } else if (action.id === 'restoreTemplate') {
          return this.doRestoreTemplate();
        } else if (action.id === 'comment') {
          return this.doComment();
        } else if (action.id === 'addClasswork') {
          return this.doAddClasswork();
        } else if (action.id === 'changeHistory') {
          return this.doShowChangeHistory();
        } else if (action.id === 'swap') {
          return this.doSwapLesson();
        }
        return Promise.resolve();
      })
      .then(() => {
        if (this.isLessonLists) {
          if (this.willResetLessonListsData(action)) {
            if (this.localPage === 'lesson-banks') {
              this.$eventBus.$emit('resetLessonBankData', { lessonDeleted: action.id === 'delete' });
            } else {
              this.$eventBus.$emit('lessonHasBeenSave');
            }
          } else {
            CommonUtils.hideLoading();
          }
        }
      })
      .finally(() => {
        if (!this.isLessonLists) {
          CommonUtils.hideLoading();
        }
        if (action.id !== 'applyTemplate') {
          that.showMenu = false;
        }
      });
  }

  async doShowChangeHistory() {
    this.showMenu = false;
    return this.loadChangeHistory().then(() => {
      this.localShowChangeHistory = true;
    });
  }

  async doAddClasswork() {
    this.showMenu = false;
    if ((this.hasAssignments || this.hasAssessments)) {
      return this.$refs.classworkAction.classwork(this.input.data.classId).finally(() => {
        this.closeEditor();
      });
    } else {
      return this.$eventBus.$emit('openSubPage', {
        type: 'classwork',
        modal: true,
        input: {
          action: 'A',
          loadData: true,
          classId: this.input.data.classId,
          unit: this.input.data.unitId !== 0 ? this.input.data.unitNum + ' - ' + this.input.data.unitTitle : undefined,
          type: 0,
          startDate: this.input.data.customStart ? moment(this.input.data.customStart, 'hh:mm A').format('MM/DD/YYYY') : '',
          endDate: this.input.data.customEnd ? moment(this.input.data.customEnd, 'hh:mm A').format('MM/DD/YYYY') : '',
          showClassworkPickerAfterSave: true,
          fromLessonAction: true
        }
      });
    }
  }

  async doApplyTemplate(template: any) {
    this.showMenu = false;
    if (template.id === 0) {
      return;
    }
    this.applyTemplate({
      eventId: template.id,
      templateId: template.id,
      classId: this.input.data.classId,
      when: 'E',
      start: this.input.data.date,
      end: this.input.data.date,
      extraClass: this.input.data.extraLesson
    }).then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg51') as string);
      }

      return Promise.resolve();
    }).then(() => {
      return this.reloadPlans();
    })
  }

  async doRemoveTemplate() {
    this.showMenu = false;
    const payload = {
      classId: this.input.data.classId,
      when: 'E',
      start: this.input.data.date,
      end: this.input.data.date,
      extraClass: this.input.data.extraLesson,
      templateCustomDayCode: this.input.data.templateCustomDayCode
    }
    this.removeRestoreTemplate(payload).then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg52') as string);
      }

      return Promise.resolve();
    }).then(() => {
      return this.reloadPlans();
    })
  }

  async doRestoreTemplate() {
    this.showMenu = false;
    const payload = {
      classId: this.input.data.classId,
      when: 'E',
      start: this.input.data.date,
      end: this.input.data.date,
      extraClass: this.input.data.extraLesson,
      templateCustomDayCode: 'BT'
    }
    this.removeRestoreTemplate(payload).then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg53') as string);
      }

      return Promise.resolve();
    }).then(() => {
      this.reloadPlans();
    })
  }

  get teacherId() {
    return this.input.data.teacherId;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  async doComment() {
    this.showMenu = false;
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      modal: true,
      input: {
        action: 'reply',
        recipient: this.teacherId,
        lessonId: this.lessonId,
        className: this.className,
        lessonDate: this.lessonDate
      }
    });
    return Promise.resolve();
  }

  async doPrintOptions() {
    const that = this;
    this.showMenu = false;
    return that.$refs.printOptionsAction.print({
      type: 'day',
      classId: this.classId,
      extraLesson: this.extraLesson,
      lessonDate: this.lessonDate,
      classIds: this.classIds
    }).finally(() => {
      this.closeEditor();
    });
  }

  async doPrintLesson() {
    const that = this;
    this.showMenu = false;
    if (this.localPage === 'lesson-banks') {
      return this.initBankPrint({
        lessonDate: this.lessonDate,
        lessonBankId: this.className
      }).then(() => {
        return that.loadNotesStudents();
      }).then(() => {
        return that.print({
          authToken: UserSession.getAccessToken()
        });
      }).finally(() => {
        this.closeEditor();
      });
    } else {
      return this.initDayPrint({
        type: 'day',
        classId: this.classId,
        extraLesson: this.extraLesson,
        lessonDate: this.lessonDate,
        classIds: this.classIds
      }).then(() => {
        return that.loadNotesStudents();
      }).then(() => {
        return that.print({
          authToken: UserSession.getAccessToken()
        });
      }).finally(() => {
        this.closeEditor();
      });
    }
  }

  async doMoveLesson() {
    this.showMenu = false;
    return this.$refs.moveAction.move().finally(() => {
      this.closeEditor();
    });
  }

  async doSwapLesson() {
    this.showMenu = false;
    return this.$refs.swapAction.swap().finally(() => {
      this.closeEditor();
    });
  }

  async doLinkLesson() {
    this.showMenu = false;
    return this.$refs.linkAction.link().finally(() => {
      this.closeEditor();
    });
  }

  async doEditLink() {
    if (this.input.loadData) {
      return this.editLesson(true);
    } else {
      this.editLink = true;
      return this.doInit({ loadData: true, data: this.input.data });
    }
  }

  async doBumpForward() {
    return this.doBump({ numDays: this.forward, verify: true }).then(resp => {
      const count = resp.data.count;
      if (count && count > 0) {
        const dates = resp.data.lessonDates;
        let message = '';
        if (count > 1) {
          message = this.$t('bumpLessonsMessage', { count: count, startDate: dates[0], endDate: dates[dates.length - 1] }).toString();
        } else {
          message = this.$t('bumpLessonMessage', { date: dates[0] }).toString();
        }
        return this.$refs.confirm.confirm({
          title: this.$t('lessonLabel'),
          text: message,
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result: any) => {
          if (result === 1) {
            return this.doBump({ numDays: this.forward, verify: false }).then((bumpResponse: any) => {
              return this.showBumpForwardConfirmation(bumpResponse);
            })
          } else {
            return Promise.resolve();
          }
        });
      } else {
        return this.showBumpForwardConfirmation(resp);
      }
    }).finally(() => {
      this.closeEditor();
    });
  }

  async showBumpForwardConfirmation(resp: any) {
    const clone = this.cloneState();
    const data = resp.data;
    clone.numDays = this.forward;

    if (this.showSnackbarNotifications) {
      this.showNotification(this.headerTitle, this.$t('statusMsg1') as string, () => {
        CommonUtils.showLoading();
        this.doUndoBumpForward({ ...clone, bumpedLessonId: data.bumpedLessonId, action: 'UNDOBUMPLESSON' }).then(() => {
          this.$snotify.success(this.$t('statusMsg27') as string);
          return Promise.resolve();
        }).finally(CommonUtils.hideLoading);
      });
    }

    return Promise.resolve();
  }

  async doBumpBackward() {
    const bumpText = this.backward > 1 ? '' + this.backward + ' ' + this.$t('lessonsLabel') : '' + this.$t('lessonLabel');
    const message = this.$t('confirmBumpMsg', { bump: bumpText.toLocaleLowerCase() }) as string;
    return this.$refs.confirm.confirm({
      title: this.$t('lessonLabel'),
      text: message,
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then(async (result: number) => {
      if (result === 1) {
        return this.doBump({ numDays: -1 * this.backward }).then(resp => {
          const clone = this.cloneState();
          const data = resp.data;
          clone.numDays = this.backward;

          if (this.showSnackbarNotifications) {
            this.showNotification(this.headerTitle, this.$t('statusMsg1') as string, () => {
              CommonUtils.showLoading();
              this.doUndoBumpBackward({ ...clone, bumpedLessonId: data.bumpedLessonId, lessonIds: data.lessonIds }).then(() => {
                this.$snotify.success(this.$t('statusMsg27') as string);
                return Promise.resolve();
              }).finally(CommonUtils.hideLoading);
            });
          }

          return Promise.resolve();
        });
      }
      this.closeEditor()
      return Promise.resolve();
    }).finally(() => {
      this.closeEditor();
    });
  }

  async showNotification(title: string, message: string, undoAction: () => void | undefined) {
    this.$snotify.confirm(title, message, {
      timeout: 5000,
      showProgressBar: false,
      buttons: [
        {
          text: this.$t('undoLabel') as string,
          action: (toast: SnotifyToast) => {
            this.$snotify.remove(toast.id, true);
            undoAction();
          },
          bold: true
        },
        {
          text: this.$t('closeLabel') as string,
          action: (toast: SnotifyToast) => {
            this.$snotify.remove(toast.id);
          }
        }
      ]
    });
  }

  async doPasteLesson() {
    return Promise.resolve().then(async () => {
      if (this.hasData) {
        return this.$refs.confirm.confirm({
          title: this.$t('lessonLabel'),
          text: this.$t('confirmOverwriteLessonInfoMsg'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result) => {
          if (result === 1) {
            return this.doPaste(CommonUtils.getPage(this.$route)).then(() => {
              this.$eventBus.$emit('lessonHasBeenPasted', this.input.data);
              return Promise.resolve();
            });
          } else {
            return Promise.resolve();
          }
        });
      } else {
        return this.doPaste(CommonUtils.getPage(this.$route)).then(() => {
          this.$eventBus.$emit('lessonHasBeenPasted', this.input.data);
          return Promise.resolve();
        });
      }
    }).finally(() => {
      CommonUtils.hideLoading();
      this.closeEditor();
    })
  }

  async doSetNoClassDay() {
    return this.$refs.confirm.confirm({
      title: this.$t('noClassDayLabel'),
      text: this.$t('noClassDayShiftMsg'),
      option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
      option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
    }).then(async result => {
      if (result === 1) {
        return this.doRemoveClassDay({ shiftLessons: 'Y' }).then((resp) => {
          const clone = this.cloneState();
          const data = resp.data;
          clone.shiftLessons = 'Y';
          clone.lessonIds = data.lessonIds;
          if (this.showSnackbarNotifications) {
            this.showNotification(this.headerTitle, this.$t('statusMsg28') as string, () => {
              CommonUtils.showLoading();
              this.doUndoBumpForward({ ...clone, bumpedLessonId: data.bumpedLessonId, action: 'UNDOBUMPLESSON', deleteCustomDay: true }).then(() => {
                this.$snotify.success(this.$t('statusMsg27') as string);
                return Promise.resolve();
              }).finally(CommonUtils.hideLoading);
            });
          }
          return Promise.resolve();
        });
      } else if (result === 2) {
        return this.doRemoveClassDay({ shiftLessons: 'N' }).then((resp) => {
          const clone = this.cloneState();
          const data = resp.data;
          clone.shiftLessons = 'N';
          clone.lessonIds = data.lessonIds;
          if (this.showSnackbarNotifications) {
            this.showNotification(this.headerTitle, this.$t('statusMsg28') as string, () => {
              CommonUtils.showLoading();
              this.doUndoDeleteLesson({ ...clone, bumpedLessonId: data.bumpedLessonId, deleteCustomDay: true }).then(() => {
                this.$snotify.success(this.$t('statusMsg27') as string);
                return Promise.resolve();
              }).finally(CommonUtils.hideLoading);
            });
          }
          return Promise.resolve();
        });
      } else {
        return Promise.resolve();
      }
    }).finally(() => {
      this.closeEditor();
    });
  }

  async doUnlink(params?: any) {
    return this.unlink(params).then(async resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('lessonsUnlinked') as string);
      }

      return Promise.resolve(resp);
    });
  }

  async doUnlinkLesson() {
    return this.$refs.confirm.confirm({
      title: this.$t('unlinkLessonLabel'),
      text: this.$t('unlinkMsg'),
      option1ButtonAlternativeText: this.$t('yesLabel'),
      option2ButtonAlternativeText: this.$t('noLabel')
    }).then((result) => {
      if (result === 1) {
        return this.doUnlink({ linkAction: 1 });
      } else if (result === 2) {
        return this.doUnlink();
      } else {
        return Promise.resolve();
      }
    }).finally(() => {
      this.closeEditor();
    });
  }

  async doDeleteLesson() {
    if (this.localPage !== 'lesson-banks') {
      return this.$refs.confirm.confirm({
        title: this.$t('deleteLessonLabel'),
        text: this.$t('classScheduleChangeAlertMsg'),
        option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
        option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
      }).then(async (result) => {
        if (result === 1) {
          return this.doDelete({ shiftLessons: 'Y' }).then((resp) => {
            const clone = this.cloneState();
            const data = resp.data;
            clone.shiftLessons = 'Y';
            clone.lessonIds = data.lessonIds;
            if (this.showSnackbarNotifications) {
              this.showNotification(this.headerTitle, this.$t('statusMsg3') as string, () => {
                CommonUtils.showLoading();
                this.doUndoDeleteLesson({ ...clone, bumpedLessonId: data.bumpedLessonId }).then(() => {
                  this.$snotify.success(this.$t('statusMsg27') as string);
                  return Promise.resolve();
                }).finally(CommonUtils.hideLoading);
              });
            }
            return Promise.resolve();
          });
        } else if (result === 2) {
          return this.doDelete({ shiftLessons: 'N' }).then((resp) => {
            const clone = this.cloneState();
            const data = resp.data;
            clone.shiftLessons = 'N';
            clone.lessonIds = data.lessonIds;
            if (this.showSnackbarNotifications) {
              this.showNotification(this.headerTitle, this.$t('statusMsg3') as string, () => {
                CommonUtils.showLoading();
                this.doUndoDeleteLesson({ ...clone, bumpedLessonId: data.bumpedLessonId }).then(() => {
                  this.$snotify.success(this.$t('statusMsg27') as string);
                  return Promise.resolve();
                }).finally(CommonUtils.hideLoading);
              });
            }
            return Promise.resolve();
          });
        } else {
          return Promise.resolve();
        }
      }).finally(() => {
        this.closeEditor();
      });
    } else {
      return this.$refs.confirm.confirm({
        title: this.$t('deleteLessonLabel'),
        text: this.$t('confirmDeleteLesson4'),
        option1ButtonAlternativeText: this.$t('deleteLabel')
      })
        .then(async (result) => {
          if (result === 1) {
            return this.doDelete({ shiftLessons: 'N' }).then((resp) => {
              const clone = this.cloneState();
              const data = resp.data;
              clone.shiftLessons = 'N';
              clone.lessonIds = data.lessonIds;

              if (this.showSnackbarNotifications) {
                this.showNotification(this.headerTitle, this.$t('statusMsg3') as string, () => {
                  CommonUtils.showLoading();
                  this.doUndoDeleteLesson({ ...clone, bumpedLessonId: data.bumpedLessonId }).then(() => {
                    this.$snotify.success(this.$t('statusMsg27') as string);
                    return Promise.resolve();
                  }).finally(CommonUtils.hideLoading);
                });
              }

              return Promise.resolve();
            });
          } else {
            CommonUtils.hideLoading();
            return Promise.reject();
          }
        })
        .finally(() => {
          this.closeEditor();
        });
    }
  }

  toggleLessonLock() {
    this.lessonLock = CommonUtils.booleanToString(!CommonUtils.isTrue(this.lessonLock));
    if (this.input.loadData) {
      return this.doSave();
    } else {
      return Promise.resolve({});
    }
  }

  editLesson(editLink: boolean, loadData?: boolean) {
    this.editLink = editLink;
    let isModal = true;
    if (this.input.lessonListsView) {
      isModal = this.$currentUser.defaultEditorMode === 'modal';
    }
    this.$eventBus.$emit('openSubPage', {
      type: 'lesson',
      width: isModal ? undefined : 600,
      modal: isModal,
      input: {
        loadData: loadData || this.input.lessonListsView || false,
        data: this.input.data,
        lessonListsView: this.input.lessonListsView
      }
    });
    return Promise.resolve();
  }

  async doInit(input?: any) {
    const that = this;
    return this.init(input || this.input).then(function() {
      that.initialized = true;
      that.save = false;
    });
  }

  async addRemoveStatus(status: any) {
    if (this.selected.includes(status.lessonStatusId)) {
      this.statuses = this.statuses.filter((s: any) => s.lessonStatusId !== status.lessonStatusId);
    } else {
      this.statuses = this.statuses.concat(status);
    }
    if (this.input.loadData) {
      return this.doSave(this.localPage === 'lesson-banks' ? { isCalledByLessonBank: true } : undefined).then(() => {
        if (this.localPage === 'lesson-banks') {
          this.$eventBus.$emit('lessonHasBeenSave', true);
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  willResetLessonListsData(action:any):boolean {
    return (action.id !== 'copy' && action.id !== 'changeHistory' && action.id !== 'edit');
  }

  closeEditor() {
    this.$eventBus.$emit('closeSubPage');
  }

  @Watch('input')
  @Watch('showMenu')
  onInputChange() {
    if (this.showMenu) {
      this.doInit();
    }
  }

  created() {
    if (this.showMenu) {
      this.doInit();
    }
  }

  doSaveClasswork(classworkSelected: any) {
    const that = this;
    CommonUtils.showLoading();
    const assignments:any[] = [];
    const assessments:any[] = [];
    for (const classwork of classworkSelected) {
      if (classwork.isAssignment) {
        assignments.push(classwork);
      } else {
        assessments.push(classwork);
      }
    }
    this.assignments = assignments;
    this.assessments = assessments;
    this.$store.commit('lessons/setClassId', this.input.data.classId);
    this.$store.commit('lessons/setDate', this.input.data.date);
    this.$store.commit('lessons/setExtraLesson', this.input.data.extraLesson);
    this.doSave().then(() => {
      CommonUtils.hideLoading();
      that.$refs.classworkAction.closeModal();
    });
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.$eventBus.$on('classworkHasBeenAddedFromLessonAction', (data:any) => {
        let classworkId = 0;
        const respAssignments = data.resp.assignments || [];
        const respAssessments = data.resp.assessments || [];
        const respClasswork = respAssignments.concat(respAssessments);
        for (const c of respClasswork) {
          if ((c.assignmentTitle === data.localData.title || c.assessmentTitle === data.localData.title) && c.subjectId === data.localData.classID && (c.assignmentDesc === data.localData.description || c.assessmentDesc === data.localData.description)) {
            classworkId = c.assignmentId || c.assessmentId;
          }
        }
        that.$refs.classworkAction.classwork(data.localData.classID, classworkId);
      });
    });
  }

  destroyed() {
    this.$eventBus.$off('classworkHasBeenAddedFromLessonAction');
  }

  timezoneDate(date:any) {
    return this.formatTimeStampToDisplay(moment.utc(date).local().format('MM/DD/YYYY HH:mm:ss'), true);
  }
}
