
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ld from 'lodash';
import CommonUtils from '@/utils/common-utils';

const lessonactions = namespace('lessonactions');
const settings = namespace('settings');

@Component
export default class CopyItemsPicker extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @lessonactions.Getter('getCopyLabel')
  copyLabel!: any;

  @settings.Getter
  getLessonSectionLabel!: (params: string) => string;

  @settings.Getter
  isLessonSectionEnabled!: (params: string) => boolean;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get copy(): Array<any> {
    return this.$store.state.lessonactions.copy;
  }

  set copy(value: Array<any>) {
    this.$store.commit('lessonactions/setCopy', value);
  }

  get filteredCopyItems(): Array<any> {
    const sections = this.$store.state.lessons.sections;
    return this.copy.filter((c) => {
      if (CommonUtils.isNotEmpty(sections)) {
        for (const i in sections) {
          const section = sections[i];
          if (section.section === c.section) {
            return section.enabled;
          }
        }
      }
      return (this.isLessonSectionEnabled(c.section) || c.section === 'all' || c.section === 'attachments' || c.section === 'linkedLessonId' || c.section === 'lessonTitle')
    }).map((c) => {
      const item = ld.cloneDeep(c);
      item.label = this.getSectionLabel(item);
      if (CommonUtils.isNotEmpty(sections)) {
        for (const i in sections) {
          const section = sections[i];
          if (section.section === c.section) {
            item.label = section.label;
            break;
          }
        }
      }
      return item;
    });
  }

  onItemClick(c: any) {
    const copyItems = ld.cloneDeep(this.copy);
    if (c.section === 'all') {
      copyItems.forEach((item) => {
        item.selected = item.section === 'all';
      });
    } else {
      let selectedCount = 0;
      copyItems.forEach((item) => {
        if (c.section === item.section) {
          item.selected = !item.selected;
        }
        if (item.selected) {
          selectedCount++;
        }
      });
      if (selectedCount > 0) {
        copyItems[0].selected = false;
      } else {
        copyItems[0].selected = true;
      }
    }
    this.copy = copyItems;
  }

  getSectionLabel(c: any) {
    const section = c.section;
    if (section === 'all') {
      return this.$t('allSectionsLabel');
    } else if (section === 'attachments') {
      return this.$t('attachmentsLabel');
    } else if (section === 'linkedLessonId') {
      return this.$t('linkedLessonLabel');
    } else if (section === 'lessonTitle') {
      return this.$t('lessonTitleLabel');
    } else {
      return this.getLessonSectionLabel(section);
    }
  }

  focus() {
    (this.$refs as any).menuActivator.focus();
  }
}
