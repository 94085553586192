import { render, staticRenderFns } from "./CopyItemsPicker.vue?vue&type=template&id=27f717cf"
import script from "./CopyItemsPicker.vue?vue&type=script&lang=ts"
export * from "./CopyItemsPicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCheckbox,VDivider,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27f717cf')) {
      api.createRecord('27f717cf', component.options)
    } else {
      api.reload('27f717cf', component.options)
    }
    module.hot.accept("./CopyItemsPicker.vue?vue&type=template&id=27f717cf", function () {
      api.rerender('27f717cf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pickers/CopyItemsPicker.vue"
export default component.exports