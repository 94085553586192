import { render, staticRenderFns } from "./SwapLessonAction.vue?vue&type=template&id=02d3bf97"
import script from "./SwapLessonAction.vue?vue&type=script&lang=ts"
export * from "./SwapLessonAction.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCol,VContainer,VForm,VRow,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02d3bf97')) {
      api.createRecord('02d3bf97', component.options)
    } else {
      api.reload('02d3bf97', component.options)
    }
    module.hot.accept("./SwapLessonAction.vue?vue&type=template&id=02d3bf97", function () {
      api.rerender('02d3bf97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lessons/actions/SwapLessonAction.vue"
export default component.exports