
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PlusMinusField extends Vue {
  @Prop({ type: Number, default: 1 })
  value!: number;

  @Prop({ type: Number, required: false, default: 1 })
  min!: number;

  @Prop({ type: Number, required: false, default: 10 })
  max!: number;

  get localValue() {
    return this.value;
  }

  set localValue(val: number) {
    this.$emit('input', val);
  }

  focus() {
    (this.$refs as any).decrement.focus();
  }

  focusField(refName: string) {
    if ((this.$refs as any)[refName].$el) {
      (this.$refs as any)[refName].$el.focus();
    } else {
      (this.$refs as any)[refName].focus();
    }
  }

  increment() {
    if (this.localValue < this.max) {
      this.localValue++;
    }
  }

  decrement() {
    if (this.localValue > this.min) {
      this.localValue--;
    }
  }

  @Watch('localValue')
  onLocalValueChange(n: number, o: number) {
    if (n > this.max || n < this.min) {
      this.localValue = o;
    }
  }
}
