


















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import MultiListPicker from '../../pickers/MultiListPicker.vue';
import ld from 'lodash';
import moment from 'moment';
import DateTimeUtils from '@/utils/date-time-utils';

const classwork = namespace('classwork');
@Component({
  components: {
    MultiListPicker
  }
})
export default class ClassworkLessonAction extends Vue {
  localValue = false;
  localResolve: any;
  localSearch = '';
  selectedRows: Array<any> = [];
  localClasswork:any[] = [];
  localRecentlyAddedClassworkId = 0;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: { date: '' } };
    }
  })
  input!: any;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  fromLessonAction!:boolean;

  @classwork.Action
  loadClasswork!: (param: any) => Promise<any>;

  @classwork.Getter
  getAllClasswork!: any;

  $refs!: {
    classworkPicker: MultiListPicker
  }

  @Watch('localValue')
  onLocalValueChange() {
    this.doInit();
  }

  get assignments() {
    return this.$store.state.lessons.assignments || [];
  }

  get assessments() {
    return this.$store.state.lessons.assessments || [];
  }

  get date() {
    return this.$store.state.lessons.date;
  }

  mounted() {
    this.doInit();
  }

  doInit() {
    const that = this;
    if (this.localValue) {
      this.selectedRows = [];
      for (const classwork of that.localClasswork) {
        classwork.shortValue = classwork.classworkTitle;
        classwork.key = classwork.classworkId;
        let foundAssignment = false;
        let foundAssessment = false;
        foundAssignment = that.assignments.some((a:any) => {
          if (a.assignmentId === classwork.classworkId || a.alternateLink === classwork.classworkId) {
            return true;
          }
        });
        foundAssessment = that.assessments.some((a:any) => {
          if (a.assessmentId === classwork.classworkId || a.alternateLink === classwork.classworkId) {
            return true;
          }
        });
        if (foundAssignment || foundAssessment) {
          that.selectedRows.push(classwork.key);
        }
      }
      if (this.localRecentlyAddedClassworkId !== 0) {
        that.selectedRows.push(this.localRecentlyAddedClassworkId);
      }
    }
  }

  isClassworkBoundByDate(c: any) {
    if (CommonUtils.hasText(c.classworkStart) && CommonUtils.hasText(c.classworkEnd) &&
        DateTimeUtils.isThisDateBeforeThatDate(c.classworkStart, this.date) && DateTimeUtils.isThisDateAfterThatDate(c.classworkEnd, this.date)) {
      return true;
    } else if (CommonUtils.hasText(c.classworkStart) && DateTimeUtils.isThisDateEqualToThatDate(c.classworkStart, this.date)) {
      return true;
    } else if (CommonUtils.hasText(c.classworkEnd) && DateTimeUtils.isThisDateEqualToThatDate(c.classworkEnd, this.date)) {
      return true;
    }

    return false;
  }

  public classwork(subjectId:number, classworkId?:number) {
    const that = this;
    CommonUtils.showLoading();
    this.$store.commit('classwork/setHaveClasswork', false);
    this.loadClasswork({
      teacherId: 0
    }).then(() => {
      that.localClasswork = ld.cloneDeep(that.getAllClasswork.filter((c:any) => c.subjectId === subjectId));
      that.localClasswork = that.localClasswork.map((c: any) => {
        c.disabled = that.isClassworkBoundByDate(c);
        return c;
      });
      that.localValue = true;
      that.localRecentlyAddedClassworkId = 0;
      if (classworkId) {
        that.localRecentlyAddedClassworkId = classworkId;
        that.selectedRows.push(this.localRecentlyAddedClassworkId);
      }

      for (const classwork of that.localClasswork) {
        classwork.shortValue = classwork.classworkTitle;
        classwork.key = classwork.classworkId;
      }
      that.$refs.classworkPicker.setLocalSelected(this.selectedRows);

      CommonUtils.hideLoading();
    });
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  doApply(selected: Array<any>) {
    const newSelected = this.getAllClasswork.filter((c:any) => selected.includes(c.classworkId));
    this.$emit('saveClasswork', newSelected);
  }

  doAddClasswork() {
    // this.closeModal();
    this.$eventBus.$emit('openSubPage', {
      type: 'classwork',
      modal: true,
      lessonAddClasswork: true,
      input: {
        action: 'A',
        loadData: true,
        classId: this.input.data.classId,
        unit: this.input.data.unitId !== 0 ? this.input.data.unitNum + ' - ' + this.input.data.unitTitle : undefined,
        type: 0,
        startDate: this.input.data.customStart ? moment(this.input.data.customStart, 'hh:mm A').format('MM/DD/YYYY') : '',
        endDate: this.input.data.customEnd ? moment(this.input.data.customEnd, 'hh:mm A').format('MM/DD/YYYY') : '',
        showClassworkPickerAfterSave: true,
        fromLessonAction: this.fromLessonAction
      }
    });
  }

  closeModal() {
    this.localValue = false;
  }
}
