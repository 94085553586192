



























import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../../core/Confirm.vue';

const lessons = namespace('lessons');
const classes = namespace('classes');

@Component
export default class SwapLessonAction extends Vue {
  targetDate = '';
  targetClassId = 0;
  localValue = false;
  localResolve: any;

  $refs!: {
    confirm: Confirm,
    form: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  @lessons.State
  date!: string;

  @lessons.State
  dayOfWeek!: string;

  @lessons.State
  className!: string;

  @lessons.State
  classId!: number;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @classes.Getter
  getClassName!: (param?: string | number) => string;

  @lessons.Action('swap')
  doSwap!: (params?: any) => Promise<any>;

  error = '';

  public swap() {
    this.localValue = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  get classDate() {
    return this.dayOfWeek + ' ' + this.date;
  }

  get targetClassName() {
    return this.getClassName(+this.targetClassId) || '';
  }

  get targetClassRules() {
    return [(v: string) => !!v || 'Required.'];
  }

  get targetDateRules() {
    return [() => {
      return CommonUtils.hasText(this.error) ? this.error : true
    }];
  }

  doInit() {
    this.error = '';
    if (this.localValue) {
      this.targetDate = '';
      this.targetClassId = this.classId;
    }
  }

  @Watch('localValue')
  onLocalValueChange() {
    CommonUtils.hideLoading();
    if (this.localValue) {
      this.doInit();
      try {
        this.$refs.form.resetValidation();
      } catch (e) {}
    }
  }

  mounted() {
    this.doInit();
  }

  async doApply() {
    const request = {
      subjectId: this.targetClassId,
      date: this.targetDate,
      extraLesson: 0
    }
    this.error = '';
    if (this.$refs.form.validate()) {
      CommonUtils.showLoading();
      this.doSwap(request).then(async (resp) => {
        if (resp.data.invalidDate) {
          this.error = this.$t('dateCannotBeOutsideMsg') as string;
          this.$refs.form.validate();
          return Promise.resolve(resp);
        } else {
          this.localValue = false;
          this.localResolve(resp);
          return Promise.resolve(resp);
        }
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    } else {
      return Promise.resolve();
    }
  }
}

