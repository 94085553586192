var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "plus-minus-field" }, [
    _c(
      "div",
      {
        ref: "decrement",
        class: { "cursor-not-allowed": _vm.localValue == _vm.min },
        attrs: { tabindex: "0", role: "button" },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.decrement.apply(null, arguments)
          },
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.decrement.apply(null, arguments)
          },
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "right", 39, $event.key, [
                  "Right",
                  "ArrowRight",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 2) return null
              return _vm.focusField("input")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 0) return null
              return _vm.$emit("blur")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("blur")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("blur")
            },
          ],
        },
      },
      [_vm._v("-")]
    ),
    _c("div", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localValue,
            expression: "localValue",
          },
        ],
        ref: "input",
        attrs: { "aria-label": _vm.$t("daysLabelCapital") },
        domProps: { value: _vm.localValue },
        on: {
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "right", 39, $event.key, [
                  "Right",
                  "ArrowRight",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 2) return null
              return _vm.focusField("increment")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 0) return null
              return _vm.focusField("decrement")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("blur")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("blur")
            },
          ],
          input: function ($event) {
            if ($event.target.composing) return
            _vm.localValue = $event.target.value
          },
        },
      }),
    ]),
    _c(
      "div",
      {
        ref: "increment",
        class: { "cursor-not-allowed": _vm.localValue == _vm.max },
        attrs: { tabindex: "0", role: "button" },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.increment.apply(null, arguments)
          },
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.increment.apply(null, arguments)
          },
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 0) return null
              return _vm.focusField("input")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("blur")
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("blur")
            },
          ],
        },
      },
      [_vm._v("+")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }