var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("multi-list-picker", {
    ref: "classworkPicker",
    attrs: {
      label: _vm.$t("classworkLabel"),
      items: _vm.localClasswork,
      selected: _vm.selectedRows,
      withRefresh: false,
      contentClass: "classwork-picker-content",
    },
    on: { apply: _vm.doApply },
    scopedSlots: _vm._u([
      {
        key: "prepend-action-buttons",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                staticClass: "mr-3",
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.doAddClasswork.apply(null, arguments)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("addClassworkLabel")))])]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }